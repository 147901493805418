import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Slider,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import {
  RadialBarChart,
  RadialBar,
  Customized,
  ResponsiveContainer,
} from "recharts"
import formatCurrency from "../util/formatCurrency"

const Label = ({ name, value }) => (
  <Box>
    <Typography variant="subtitle1" style={{ marginRight: `.5em` }}>
      {name}
      {value && `:`}
    </Typography>
    {value && (
      <Typography variant="subtitle1" color="textSecondary">
        {value}
      </Typography>
    )}
  </Box>
)

const LegendLabel = ({ name, value, bgcolor, ...other }) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box
        display="inline-block"
        mr={0.5}
        bgcolor={bgcolor}
        borderRadius={15}
        height={30}
        width={30}
        {...other}
      ></Box>
      <Label name={name} value={value} />
    </Box>
  )
}

const MortageCalculator = ({
  price = 670565,
  commonCharges = 0,
  taxes = 300,
}) => {
  const theme = useTheme()
  const [cost, setCost] = useState(0)
  const [salePrice, setSalePrice] = useState(price)
  const [interestRate, setInterestRate] = useState(0.0325)
  const [downPayment, setDownPayment] = useState(0.2)
  const [term, setTerm] = useState(30)
  useEffect(() => {
    const p = salePrice - downPayment * salePrice
    const t = term * 12
    const r = interestRate / 12
    if (r) {
      setCost((p * r) / (1 - Math.pow(1 + r, t * -1)) + commonCharges + taxes)
    } else {
      setCost(p / t + commonCharges + taxes)
    }
  }, [salePrice, interestRate, downPayment, term, commonCharges, taxes])

  useEffect(() => {
    const newData = []
    if (commonCharges) {
      newData.push({
        uv: commonCharges,
        fill: theme.palette.primary.main,
      })
    }
    if (taxes) {
      newData.push({
        uv: taxes,
        fill: theme.palette.primary.dark,
      })
    }
    newData.push({
      uv: cost - commonCharges - taxes,
      fill: theme.palette.primary.alt,
    })
    setData(newData)
  }, [
    commonCharges,
    cost,
    taxes,
    theme.palette.primary.alt,
    theme.palette.primary.dark,
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ])

  const [data, setData] = useState([])
  return (
    <Box>
      <Container>
        <Grid container alignItems="center">
          <Grid lg={4} xs={12} item>
            <Typography gutterBottom variant="h2">
              Monthly Costs
            </Typography>
            <Box mb={2} mt={1} display="flex" flexDirection="column">
              <Label name="PRICE" value={formatCurrency(salePrice)} />
              <Label name="Other Costs: Stamp Duty, Legal, Survey" />
            </Box>
            <LegendLabel
              bgcolor="primary.main"
              name="Life Policy"
              value={formatCurrency(commonCharges)}
            />
            <LegendLabel
              bgcolor="primary.alt"
              name="Mortgage"
              value={formatCurrency(cost - commonCharges - taxes)}
            />
            <LegendLabel
              bgcolor="primary.dark"
              name="House and Content Insurance"
              value={formatCurrency(taxes)}
            />
          </Grid>
          <Grid lg={5} xs={12} item>
            <ResponsiveContainer aspect={1}>
              <RadialBarChart
                barSize={18}
                startAngle={90}
                endAngle={-180}
                innerRadius={`75%`}
                outerRadius={`100%`}
                data={data}
              >
                <RadialBar cx={50} cy={50} cornerRadius={9} dataKey="uv" />
                <Customized
                  component={(props) => (
                    <text
                      key={props}
                      x="50%"
                      y="50%"
                      dy="10"
                      textAnchor="middle"
                    >
                      <tspan
                        fontSize="45"
                        fontWeight="700"
                        x="50%"
                        y="50%"
                        fill="#000"
                      >
                        {formatCurrency(cost)}
                      </tspan>
                      <tspan
                        fontWeight="500"
                        fontSize="20"
                        x="50%"
                        y="60%"
                        fill="#aaa"
                      >
                        Monthly Cost
                      </tspan>
                    </text>
                  )}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid lg={3} xs={12} item>
            <Box mb={2}>
              <Typography variant="h5">Mortgage Calculator</Typography>
            </Box>
            <Label
              name="Interest Rate"
              value={`${Math.floor(interestRate * 10000) / 100}% APR`}
            />
            <Slider
              value={interestRate}
              step={0.0001}
              min={0}
              max={0.1}
              onChange={(e, val) => setInterestRate(val)}
            ></Slider>
            <Label name="Purchase Price" value={formatCurrency(salePrice)} />
            <Slider
              value={salePrice}
              min={price * 0.3}
              max={price * 1.5}
              onChange={(e, val) => setSalePrice(val)}
            ></Slider>
            <Label
              name="Deposit"
              value={`${Math.floor(downPayment * 10000) / 100}%`}
            />
            <Slider
              value={downPayment}
              step={0.05}
              min={0}
              max={1}
              onChange={(e, val) => setDownPayment(val)}
            ></Slider>
            <Label name="Term" value={`${term} Years`} />
            <Slider
              value={term}
              step={5}
              min={10}
              max={40}
              valueLabelDisplay="off"
              onChange={(e, val) => setTerm(val)}
            ></Slider>
          </Grid>
        </Grid>
        <Box textAlign="center">
          <Typography
            size="small"
            style={{ fontStyle: `oblique`, fontSize: `.8rem` }}
          >
            The mortgage calculator is for display purposes only and is not an
            indication of approval or repayment amounts.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default MortageCalculator
