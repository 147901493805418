import {
  Box,
  Container,
  Grid,
  Typography,
  Dialog,
  IconButton,
  Button,
  Hidden,
  useTheme,
  Chip,
} from "@material-ui/core"
import Layout from "../components/Layout"
import Carousel from "../components/Carousel"
import { Fragment, useRef, useState } from "react"
import { Cancel, ZoomIn } from "@material-ui/icons"
import { ReactComponent as Beds } from "../icons/beds.svg"
import { ReactComponent as Area } from "../icons/area.svg"
import { ReactComponent as Bath } from "../icons/bath.svg"
import styled from "styled-components"
import { ContactForm } from "../components/ContactFormDescription"
import ContactFormSection from "../components/ContactForm"
import ListingSection from "../components/ListingSection"
import { graphql } from "gatsby"
import TownDetails from "../components/TownDetails"
import MortgageCalculator from "../components/MortgageCalculator"
import Placeholder from "../components/Placeholder"
import formatPrice from "../util/formatPrice"
import listingsPath from "../util/listingsPath"

const FeatureIcon = styled(Box)`
  ${({ theme }) => `
  position:relative;
  ${theme.breakpoints.up(`sm`)}{
    .description {
      opacity:0;
    }
  }
  ${theme.breakpoints.up(`sm`)}{
    &:hover {
      .description {
        opacity:1;
      }
      img {
        opacity:1;
        filter:none;
      }
    }
  }
  img {
    filter:grayscale(1);
    opacity:.3;
    max-width:30px;
  }
  &:not(.amenity){
    &:not(:last-child){
      &::after {
        position:absolute;
        right:0;
        width:1px;
        height:50%;
        background-color: ${theme.palette.secondary.main};
        content: '';
      }
    }
  }
  svg {
    max-width: 40px;
    width: 100%;
    height: 100%;
    max-height: 30px;
    g {
      stroke: ${theme.palette.secondary.main}
    }
  }`}
`
const Feature = ({ Icon, iconSrc, label, description }) => {
  const theme = useTheme()
  return (
    <FeatureIcon
      position="relative"
      alignItems="center"
      justifyContent="center"
      display="flex"
      flex={1}
      className={description && `amenity`}
    >
      <Box py={1} display="flex" flexDirection="column" alignItems="center">
        {Icon && (
          <Box>
            <Icon />
          </Box>
        )}
        {iconSrc && (
          <Box>
            <img src={iconSrc} alt={label} />
          </Box>
        )}
        <Box>
          <Typography
            align={`center`}
            style={{
              fontSize: `1rem`,
              color: description ? theme.palette.primary.dark : `inherit`,
            }}
          >
            {label}
          </Typography>
          {description && (
            <Typography
              className="description"
              style={{ fontSize: `1rem` }}
              gutterBottom
            >
              {description}
            </Typography>
          )}
        </Box>
      </Box>
    </FeatureIcon>
  )
}

const ListingDetail = ({
  data: {
    listing,
    listings: { nodes: listings },
  },
}) => {
  const [fullImage, setFullImage] = useState(false)
  const [contactFormVisible, setContactFormVisible] = useState(false)
  const SmallCarousel = useRef()
  const LargeCarousel = useRef()
  let {
    title,
    price,
    soldPrice,
    status,
    images,
    image,
    bedrooms,
    bathrooms,
    type,
    amenities,
    squareFeet,
    description: {
      childMarkdownRemark: { html: description, excerpt },
    },
    metaDescription,
    features,
    realtor,
  } = listing
  images = image ? [image].concat(images) : images
  const openContactForm = () => {
    window.dataLayer?.push({
      event: `contact_form_open`,
      page: `${window.location.href}`,
      element: `listing_detail`
    })
    setContactFormVisible(true)
  }
  const PriceAndTitle = () => (
    <>
      {soldPrice && (
        <Chip
          color="primary"
          label={`Sold: ${formatPrice(soldPrice)}`}
          style={{ alignSelf: `flex-start` }}
        />
      )}
      {status && status !== `For Sale` && !soldPrice && (
        <Chip
          color="primary"
          label={status}
          style={{ alignSelf: `flex-start` }}
        />
      )}
      <Typography variant="h1">
        {title} - {bedrooms} Bed
      </Typography>
      <Typography variant="h3" gutterBottom>
        {price
          .toLocaleString(`en-IE`, {
            style: `currency`,
            currency: `eur`,
          })
          .replace(/(\.|,)00$/g, ``)}
      </Typography>
    </>
  )
  const Amenities = () => {
    if (!amenities?.length) {
      return null
    }
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt={2}
        >
          <Typography variant="h2">Lifestyle</Typography>
          <Box display={{ sm: `flex` }} width={1} alignItems="flex-start">
            {amenities.map((a) => (
              <Feature
                key={a.name}
                iconSrc={a.icon.file.url}
                label={a.name}
                description={a.description?.childMarkdownRemark.html}
              />
            ))}
          </Box>
        </Box>
      </Container>
    )
  }

  const KeyFeatures = () => {
    if (!features?.length) {
      return null
    }
    return (
      <Box mb={1}>
        <Typography variant="h4" gutterBottom>
          Key features
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          {features.map((feature, i) => (
            <Box key={`feature-${i}`}>
              <Typography style={{ textTransform: `capitalize` }}>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    )
  }
  return (
    <Layout
      title={`${bedrooms} Bed House, ${title}`}
      metaDescription={
        metaDescription?.childMarkdownRemark.metaDescription ||
        `We are delighted to bring you this ${bedrooms} bed property for sale in ${
          listing.location.name
        }, Dublin. ${excerpt.match(/^.*?[.!?](?=\s[A-Z]|\s?$)(?!.*\))/)[0]}`
      }
    >
      <Dialog
        open={contactFormVisible}
        onClose={() => setContactFormVisible(false)}
        maxWidth="md"
      >
        <Container>
          <Box p={1}>
            <ContactForm
              defaultValue={type === `For Rent` ? `rent` : `buy`}
              heading={`Contact ${realtor ? realtor.name : `Andrew Rhatigan`}`}
            />
          </Box>
        </Container>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: `black`,
            justifyContent: `center`,
          },
        }}
        fullScreen
        open={fullImage !== false}
        onClose={() => setFullImage(false)}
      >
        <Box position="absolute" right="0" top="0" zIndex={1}>
          <IconButton onClick={() => setFullImage(false)}>
            <Cancel style={{ color: `white` }} />
          </IconButton>
        </Box>
        <Carousel
          className="large-carousel"
          focusOnSelect
          ref={LargeCarousel}
          fade={true}
          arrows={false}
          asNavFor={SmallCarousel.current}
          beforeChange={(currentSlide, nextSlide) => {
            setFullImage(nextSlide)
          }}
          onInit={() => {
            document
              .getElementsByClassName(`large-carousel`)[0]
              .getElementsByClassName(`slick-slide`)
              [fullImage].focus()
          }}
          initialSlide={fullImage}
          autoPlay={false}
        >
          {!!images &&
            images.map((image, i) => (
              <div key={`large-image-${i}`}>
                <img
                  style={{
                    maxWidth: `100%`,
                    width: `100%`,
                    maxHeight: `100%`,
                  }}
                  src={image.large.src}
                  alt={
                    i === 0
                      ? `Property for sale in ${listing.address}`
                      : image.description || image.title || title
                  }
                />
              </div>
            ))}
        </Carousel>
      </Dialog>
      <Container>
        <Box mt={3}>
          <Hidden mdUp>
            <Box mb={1}>
              <PriceAndTitle />
            </Box>
          </Hidden>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Box style={{ position: `relative` }} px={`50px`}>
                {images && (
                  <>
                    <Box
                      position="absolute"
                      bottom="10px"
                      left="50px"
                      zIndex={1}
                    >
                      <IconButton
                        style={{ color: `white` }}
                        onClick={() => {
                          setFullImage(
                            SmallCarousel.current.innerSlider.state.currentSlide
                          )
                        }}
                      >
                        <ZoomIn />
                      </IconButton>
                    </Box>
                    <Carousel
                      ref={SmallCarousel}
                      autoPlay={fullImage === false}
                      pauseOnFocus
                      pauseOnHover
                    >
                      {images.map((image, i) => (
                        <Box key={`small-image-${i}`}>
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src={image.small.src}
                              alt={
                                i === 0
                                  ? `Property for sale in ${listing.address}`
                                  : image.description || image.title || title
                              }
                            />
                          </Box>
                        </Box>
                      ))}
                    </Carousel>
                  </>
                )}
                {!images && (
                  <Box display="flex" justifyContent="center" height={500}>
                    <Placeholder margin="auto" maxWidth={400} />
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between">
                {bedrooms && <Feature Icon={Beds} label={`${bedrooms} Beds`} />}
                {bathrooms && (
                  <Feature Icon={Bath} label={`${bathrooms} Baths`} />
                )}
                {squareFeet && (
                  <Feature Icon={Area} label={`${squareFeet} Sq Ft.`} />
                )}
              </Box>
            </Grid>
            <Grid item md={6}>
              <Hidden smDown>
                <PriceAndTitle />
              </Hidden>
              <div dangerouslySetInnerHTML={{ __html: description }} />
              <KeyFeatures />
              {realtor && (
                <Box mb={1}>
                  <Typography gutterBottom variant="h4">
                    CONTACT AGENT
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Box mr={1}>
                      <img src={realtor.image.fixed.src} alt={realtor.name} />
                    </Box>
                    <Box>
                      <Button
                        color="secondary"
                        id="open-contact-form-button"
                        onClick={openContactForm}
                      >
                        {realtor.name}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Amenities />
      {type === `For Sale` && (
        <Box py={2}>
          <MortgageCalculator
            price={listing.price}
            commonCharges={listing.otherFees ?? undefined}
            taxes={listing.taxes ?? undefined}
          />
        </Box>
      )}
      <Container>
        <TownDetails {...listing.location} />
      </Container>
      {listings && (
        <ListingSection
          heading="Active Listings"
          bgcolor={`white`}
          inputListings={listings}
        />
      )}
      <Container>
        <Box
          mb={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: `column`, sm: `row` }}
        >
          <Box mr={{ sm: 1 }} mb={1}>
            <Button component="a" href={listingsPath({ type })}>
              More Listings
            </Button>
          </Box>
          <Box mb={1}>
            <Button onClick={openContactForm} color="secondary">
              Contact {realtor ? realtor.name.split(` `)[0] : `Andrew`}
            </Button>
          </Box>
        </Box>
      </Container>
      <ContactFormSection
        defaultValue={type === `For Rent` ? `rent` : `buy`}
        heading={`Let's get in touch`}
      />
    </Layout>
  )
}

export const PageQuery = graphql`
  query ListingPage($id: String) {
    listing: contentfulListing(id: { eq: $id }) {
      ...Listing
    }
    listings: allContentfulListing(
      limit: 3
      sort: { order: DESC, fields: createdAt }
      filter: { id: { ne: $id }, active: { eq: true } }
    ) {
      nodes {
        ...Listing
      }
    }
  }
`

export default ListingDetail
